<template>
  <div class="page-home-contact-m">
    <div class="bc-content">
      <h1>contact</h1>


      <div class="bc-info-row first-info-row">
        <h3>INFORMATION</h3>
        <p>info@blast.jp.net</p>
      </div>
      <div class="bc-info-row">
        <h3>COMPANY</h3>
        <p>BLAST Inc.</p>
      </div>
      <div class="bc-info-row">
        <h3>ADDRESS</h3>
        <p>
          #609, 2-19-15 Shibuya<br>
          Shibuya-ku, Tokyo
        </p>
      </div>


      <div class="bc-form">

        <h3>お問い合わせ</h3>

        <v-form @submit="onSubmit">
          <div class="bc-control-row">
            <input type="text" v-model="name" placeholder="お名前" required />
          </div>
          <div class="bc-control-row">
            <input type="email" v-model="email" placeholder="メールアドレス" required />
          </div>
          <div class="bc-control-row">
            <textarea v-model="message" placeholder="メッセージ" required rows="8" />
          </div>

          <div class="bc-button-row">
            <v-btn
              class="bc-button"
              depressed
              type="submit"
              color="#E94A1F"
              dark
              rounded
            >送信する</v-btn>
          </div>
        </v-form>


      </div>


    </div>

    <bc-message-dialog ref="bcMessageDialog" @ok-button-clicked="dialogOkClicked"/>

  </div>
</template>

<script>
import Common from './common.js'
import BcMessageDialog from './bc_message_dialog.vue'

export default {
  components: {
    'bc-message-dialog': BcMessageDialog
  },
  data() {
    return {
      name: null,
      email: null,
      message: null,
      show: false
    }
  },
  methods: {
    scrolled() {
      if (!this.show) {
        this.show = Common.showElement(this.$el, 0.5, 0)
      }
    },
    onSubmit(e) {
      e.preventDefault()
      this.postContact()
    },
    dialogOkClicked(tag) {
      if (tag == 1) {
        return
      }
      if (tag == 2) {
        location.href = '/'
      }
    },
    postContact() {
      let params = {
        contact: {
          name: this.name,
          email: this.email,
          message: this.message
        }
      }
      this.$root.$refs.bcLoadingView.showView()
      Common.axios().post('/contacts', params).then(response => {
        this.$root.$refs.bcLoadingView.hideView()
        this.result = true
        setTimeout(() => {
          let message = "お問い合わせありがとうございます。"
          this.$refs.bcMessageDialog.showDialog(message, 2)
        }, 200)
      }).catch(error => {
        this.$root.$refs.bcLoadingView.hideView()
        let message = '<ul>' + error.response.data.errors.map(e => `<li>${e}</li>`).join('') + '</ul>'
        setTimeout(() => {
          this.$refs.bcMessageDialog.showDialog(message, 1)
        }, 200)
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "scoped.scss";

.page-home-contact-m {
  width: 100%;
  padding: 0 0 96px;
  display: flex;
  justify-content: center;

  opacity: 0.0;
  transition: opacity 1.2s;
  &.show {
    opacity: 1.0;
  }

  .bc-content {
    width: 100%;
    max-width: 800px;
    h1 {
      padding: 0 0 16px;
      text-align: center;
      @include font-clearface-bold();
      font-size: 40px;
      font-weight: normal;
      letter-spacing: 0;
      color: $bc-color-green;
    }
    .bc-info-row {
      padding: 30px 0 0;
      text-align: center;
      &.first-info-row {
        padding: 12px 0 0;
      }
      h3 {
        @include font-nimbus-sans-extd();
        font-size: 11px;
      }
      p {
        @include font-nimbus-sans-extd();
        font-size: 18px;
        font-weight: 500;
        line-height: 1em;
        margin: 2px 0 0;
      }
    }
    .bc-form {
      padding: 0 24px;
      h3 {
        padding: 45px 0 10px;
        text-align: center;
        font-size: 13px;
        font-weight: normal;
        color: $bc-color-green;
      }
      .bc-control-row {
        margin: 0 0 12px;

        input[type=text],
        input[type=email],
        input[type=tel],
        input[type=password],
        textarea,
        select {
          width: 100%;
          -webkit-appearance: none;
          padding: 11px 16px;
          font-size: 12px;
          border: none;
          color: #585858;
          background-color: white;
          box-sizing: border-box;
          border-radius: 0;
        }
      }
      .bc-button-row {
        margin: 20px 0 64px;
        display: flex;
        justify-content: center;
        .bc-button {
          width: 200px;
          height: 38px;
          font-size: 14px;
          font-weight: normal;
          color: $bc-color-beige;
        }
      }
    }
  }
}
</style>
