<template>
  <footer class="bc-footer-m">
    <bc-wave color="#E94A1F" background-color="#F5F0E9" />

    <div class="bc-footer-container">

      <a href="/" class="bc-logo">
        <img src="./res/logo_white.svg" />
      </a>

      <div class="bc-links">
        <div class="bc-links-col">
          <a :style="linkStyle" href="/#statement">STATEMENT</a>
          <!-- <a :style="linkStyle" href="/members">MEMBERS</a> -->
          <a :style="linkStyle" href="/#founder">FOUNDER</a>
          <a :style="linkStyle" href="/#service">SERVICE</a>
          <!-- <a :style="linkStyle" href="/blog">BLOG</a> -->
          <!-- <a :style="linkStyle" href="/news">NEWS</a> -->
          <a :style="linkStyle" href="/#contact">CONTACT</a>
          <!-- <a :style="linkStyle" href="/media">MEDIA</a> -->
        </div>
      </div>

      <div class="bc-terms">
        <div class="bc-terms-col">
          <a :style="termStyle" href="/pages/privacy_policy">プライバシーポリシー</a>
          <a :style="termStyle" href="/pages/terms_of_service">サービス利用規約</a>
          <a :style="termStyle" href="https://nagi-jp.com/pages/law">特定取引法に基づく表記</a>
        </div>
        <div class="bc-terms-col">
          <p :style="copyrightStyle" class="bc-copyright">&copy;2023 BLAST Inc.</p>
        </div>
      </div>

    </div>


  </footer>
</template>

<script>
import Common from './common.js'
import BcWave from './bc_wave.vue'

export default {
  components: {
    'bc-wave': BcWave,
  },
  data() {
    return {
      w: 0,
      isBottom: false
    }
  },
  computed: {
    linkStyle() {
      return {
        fontSize: '12px'
      }
    },
    termStyle() {
      let fontSize = 9
      return {
        paddingBottom: 16 + 'px',
        fontSize: fontSize + 'px'
      }
    },
    copyrightStyle() {
      let fontSize = 9
      return {
        paddingBottom: 16 + 'px',
        fontSize: fontSize + 'px'
      }
    }
  },
  watch: {
    isBottom(newVal, oldVal) {
      if (newVal) {
        document.body.style.backgroundColor = '#E94A1F'
      } else {
        document.body.style.backgroundColor = '#F5F0E9'
      }
    }
  },
  methods: {
    resized() {
      this.w = window.innerWidth
    },
    scrolled() {
      this.isBottom = (document.body.clientHeight - 128 <= window.scrollY + window.innerHeight)
    }
  },
  mounted() {
    window.addEventListener('resize', this.resized)
    window.addEventListener('scroll', this.scrolled)
    setTimeout(() => {
      this.resized()
    }, 200)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrolled)
    window.removeEventListener('resize', this.resized)
  }
}
</script>

<style scoped lang="scss">
@import "scoped.scss";

.bc-footer-m {
  position: relative;
  width: 100%;
  background-color: $bc-color-red;

  .bc-footer-container {
    box-sizing: border-box;
    width: 100%;
    padding: 64px 24px 16px;
    background-color: $bc-color-red;

    .bc-logo {
      width: 50%;
      display: block;
      img {
        width: 100%;
        max-height: 26px;
        object-fit: contain;
      }
    }

    .bc-links {
      width: 100%;
      padding: 52px 0 40px;

      .bc-links-col {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        a {
          width: 50%;
          margin: 8px 0;
          @include font-nimbus-sans-extd();
          font-size: 12px;
          display: block;
          color: white;
          &:visited {
            color: white;
          }
        }
      }
    }

    .bc-terms {
      padding: 0;
      width: 100%;
      display: flex;
      .bc-terms-col {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        a {
          display: block;
          color: white;
          &:visited {
            color: white;
          }
        }
        p {
          margin: 0;
          @include font-nimbus-sans-extd();
          color: white;
        }
      }
    }
  }
}
</style>
