<template>
  <section class="page-home-hero" :style="sectionStyle">

    <div class="home-hero-image" ref="luckyBagImage">
      <img src="./res/home_hero.jpg" :style="imgStyle" />
    </div>

    <div class="home-content" ref="luckyBagContent">
      <h1 :style="h1Style">
        WOMEN EMPOWERMENT COMPANY
      </h1>
    </div>

    <div class="nagi-wave-container">
      <nagi-wave color="#F5F0E9" />
    </div>

  </section>
</template>

<script>
import Common from './common.js'
import NagiWave from './bc_wave.vue'

export default {
  components: {
    'nagi-wave': NagiWave
  },
  data() {
    return {
      w: 0,
      h: 0,
      showImage: false,
      showContent: false,
    }
  },
  computed: {
    sectionStyle() {
      let h = this.w * 900 / 1440
      return {
        height: h + 'px'
      }
    },
    imgStyle() {
      return {
        width: this.w + 'px'
      }
    },
    h1Style() {
      let fontSize = Math.max(58, Math.min(62, this.w * 62 / 1440.0))
      return {
        fontSize: fontSize + 'px'
      }
    }
  },
  methods: {
    resized() {
      this.w = window.innerWidth
      this.h = window.innerHeight
    },
    scrolled() {
      if (!this.showImage) {
        this.showImage = Common.showElement(this.$refs.luckyBagImage, 0, 0)
      }
      if (!this.showContent) {
        this.showContent = Common.showElement(this.$refs.luckyBagContent, 0.7, 0)
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.resized)
    setTimeout(() => {
      this.resized()
    }, 200)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resized)
  }
}
</script>

<style scoped lang="scss">
@import "common.scss";

.page-home-hero {
  position: relative;
  width: 100vw;
  overflow: hidden;

  .home-hero-image {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    overflow: hidden;

    will-change: transform;

    transform: scale(1.1, 1.1);
    opacity: 0;
    transition: opacity 1.2s, transform 1.2s;
    &.show {
      opacity: 1.0;
      transform: scale(1.0, 1.0);
    }

    img {
      display: block;
      position: relative;
      object-fit: cover;
    }
  }

  .home-content {
    position: relative;
    left: 0;
    bottom: 4%;
    width: 100%;
    height: 90%;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;

    transform: scale(1.1, 1.1);
    opacity: 0.0;
    transition: opacity 1.2s, transform 1.2s;
    transition-delay: 0.2s;
    &.show {
      opacity: 1.0;
      transform: scale(1.0, 1.0);
    }
    img {
      width: calc(calc(803.21 / 1440.0) * 100%);
      max-width: 803.21px;
      filter: drop-shadow(rgb(0 0 0 / 10%) 0 0 10px);
      margin: -20px 0 0 0;
    }
    h1 {
      @include font-nimbus-sans-extd();
      color: white;
      line-height: 1em;
    }
  }
  .nagi-wave-container {
    position: absolute;
    width: 100vw;
    height: 12vw;
    bottom: -8px;
    z-index: 20;
  }
}
</style>
