<template>
  <div class="bc-header-menu" ref="bcHeaderMenu">

    <div class="bc-menu-header" :style="headerStyle">
      <div class="bc-menu-header-content" :style="headerContentStyle">
        <a href="/" class="bc-logo">
          <img src="./res/logo.svg" :style="logoImageStyle" />
        </a>
        <button class="bc-close-button" @click="closeButtonClicked">
          <img :style="imageStyle" src="./res/icon_close.svg" />
        </button>
      </div>
    </div>

    <div class="bc-content" :style="contentStyle">

      <div class="bc-links">
        <div :style="linkStyle">
          <a :style="linkAStyle" @click="menuClicked('/#statement')">STATEMENT</a>
          <!-- <a :style="linkAStyle" @click="menuClicked('/#about')">ABOUT</a> -->
          <a :style="linkAStyle" @click="menuClicked('/#service')">SERVICE</a>
          <a :style="linkAStyle" @click="menuClicked('/#founder')">FOUNDER</a>
          <!-- <a :style="linkAStyle" href="/news">NEWS</a>
               <a :style="linkAStyle" href="/media">MEDIA</a> -->
          <!-- <a :style="linkAStyle" href="/members">MEMBERS</a> -->
          <!-- <a :style="linkAStyle" href="/blog">BLOG</a> -->
          <a :style="linkAStyle" @click="menuClicked('/#contact')">CONTACT</a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Common from './common.js'

export default {
  data() {
    return {
      isMobile: true,
      w: 0,
      show: false,
      scrollY: 0
    }
  },
  computed: {
    headerStyle() {
      if (this.isMobile) {
        return {
          height: '48px'
        }
      }
      return {
        height: '64px'
      }
    },
    headerContentStyle() {
      if (this.isMobile) {
        return {
          paddingLeft: 16 + 'px',
          paddingRight: 8 + 'px'
        }
      }
      return {
        paddingLeft: 24 + 'px',
        paddingRight: 16 + 'px'
      }
    },
    logoImageStyle() {
      if (this.isMobile) {
        return {
          height: '20px'
        }
      }
      return {
        width: '170px'
      }
    },
    imageStyle() {
      if (this.isMobile) {
        return {
          width: '20px'
        }
      }
      return {
        width: '30px'
      }
    },
    contentStyle() {
      if (this.isMobile) {
        return {
          height: 'calc(100% - 88px)'
        }
      }
      return {}
    },
    linkStyle() {
      if (this.isMobile) {
        return {
          fontSize: 20 + 'px'
        }
      }
      let fontSize = Math.min(80, this.w * 0.0385)
      return {
        fontSize: fontSize + 'px'
      }
    },
    linkAStyle() {
      if (this.isMobile) {
        return {
          marginBottom: '20px'
        }
      }
      return {
        marginBottom: '10px'
      }
    }
  },
  methods: {
    resized() {
      this.isMobile = Common.isMobile()
      this.w = window.innerWidth
    },
    showMenu() {
      this.scrollY = window.scrollY
      this.$refs.bcHeaderMenu.style.display = 'flex'
      setTimeout(() => {
        this.$refs.bcHeaderMenu.classList.add('show')
      }, 200)
    },
    hideMenu() {
      this.$refs.bcHeaderMenu.classList.remove('show')
      window.scroll(0, this.scrollY)
      setTimeout(() => {
        this.$refs.bcHeaderMenu.style.display = 'none'
      }, 800)
    },
    closeButtonClicked() {
      this.hideMenu()
    },
    menuClicked(path) {
      this.hideMenu()
      location.href = path
    }
  },
  mounted() {
    window.addEventListener('resize', this.resized)
    setTimeout(() => {
      this.resized()
    }, 200)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resized)
  }
}
</script>

<style scoped lang="scss">
@import "scoped.scss";

.bc-header-menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  display: none;
  justify-content: center;
  background-color: $bc-color-bg-cream;

  opacity: 0.0;
  transition: opacity 0.8s;
  &.show {
    opacity: 1.0;
  }

  .bc-menu-header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    z-index: 1000;
    display: flex;
    justify-content: center;

    .bc-menu-header-content {
      position: relative;
      box-sizing: border-box;
      width: 100%;
      max-width: 1328px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .bc-logo {
        display: flex;
        img {
        }
      }
      .bc-close-button {
        width: 44px;
        height: 44px;
      }
    }
  }

  .bc-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .bc-links {
      a {
        margin: 0 0 10px;
        @include font-nimbus-sans-extd();
        display: block;
        text-align: center;
        color: $bc-color-green;
        &:visited {
          color: $bc-color-green;
        }
      }
    }
  }
}
</style>
