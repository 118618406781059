<template>
  <div class="bc-wave" :style="{ backgroundColor: backgroundColor }">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      viewBox="0 0 100 12"
      preserveAspectRatio="none"
    >
      <path d="m 0 15, v -10, Q 25 12, 50 5, Q 75 -1, 100 7, v 8 z" :fill="color" />
    </svg>
  </div>
</template>

<script>
export default {
  props: [
    'color',
    'backgroundColor'
  ]
}
</script>

<style scoped lang="scss">
.bc-wave {
  position: relative;
  /*   height: 10vw; */
  width: 100vw;
  height: calc(12vw + 4px);
  svg {
    position: absolute;
    width: 100%;
    height: calc(100% + 2px);
    bottom: -2px;
  }
}
</style>
