<template>
  <section class="page-home-hero-m">
    <div class="home-hero-image">
      <img src="./res/home_hero_m.jpg" ref="greenActionImage" />
    </div>

    <div class="home-content" ref="greenActionContent">
      <h1 :style="h1Style">
        WOMEN EMPOWERMENT<br>
        COMPANY
      </h1>
    </div>

    <div class="nagi-wave-container">
      <nagi-wave color="#F5F0E9" />
    </div>

  </section>
</template>

<script>
import Common from './common.js'
import NagiWave from './bc_wave.vue'

export default {
  components: {
    'nagi-wave': NagiWave
  },
  data() {
    return {
      w: 0,
      showImage: false,
      showContent: false,
    }
  },
  computed: {
    h1Style() {
      let fontSize = Math.min(28, this.w * 24.0 / 375.0)
      return {
        fontSize: fontSize + 'px'
      }
    }
  },
  methods: {
    resized() {
      this.w = window.innerWidth
    },
    scrolled() {
      if (!this.showImage) {
        this.showImage = Common.showElement(this.$refs.greenActionImage, 0.2, 0)
      }
      if (!this.showContent) {
        this.showContent = Common.showElement(this.$refs.greenActionContent, 0.6, 0)
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.resized)
    setTimeout(() => {
      this.resized()
    }, 200)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resized)
  }
}
</script>

<style scoped lang="scss">
@import "common.scss";

.page-home-hero-m {
  position: relative;
  display: flex;
  width: 100vw;
  height: 170vw;
  overflow: hidden;

  .home-hero-image {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;

      will-change: transform;

      transform: scale(1.1, 1.1);
      opacity: 0.0;
      transition: opacity 1.2s, transform 1.2s;
      &.show {
        opacity: 1.0;
        transform: scale(1.0, 1.0);
      }
    }
  }

  .home-content {
    position: relative;
    left: 0;
    top: 2%;
    width: 100%;
    height: 90%;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    transform: scale(1.1, 1.1);
    opacity: 0.0;
    transition: opacity 1.2s, transform 1.2s;
    transition-delay: 0.2s;
    &.show {
      opacity: 1.0;
      transform: scale(1.0, 1.0);
    }

    h1 {
      @include font-nimbus-sans-extd();
      color: white;
      text-align: center;
    }
  }

  .nagi-wave-container {
    position: absolute;
    width: 100vw;
    height: 12vw;
    bottom: -8px;
    z-index: 20;
  }
}
</style>
