<template>
  <div class="page-home-statement">
    <div class="bc-content">

      <h1 :style="h1Style" ref="bcTitle">私たちは、ほんとうは、もっと自由だ</h1>
      <p :style="pStyle" ref="bcText0">
        世の中のルールや、身につけた常識に、私たちは知らず知らず縛られていくけれど。<br>
        もっと自由に選んでいいことが、本当は、たくさんあるはず。
      </p>
      <p :style="pStyle" ref="bcText1">
        化粧は、自分のためにすればいい。<br>
        パンプスを履かずに、仕事してもいい。<br>
        恋人がいなくても、満ち足りた毎日は過ごせるし、結婚しなくても、子どもを持つ選択をしてもいい。
      </p>
      <p :style="pStyle" ref="bcText2">
        世界を変えたいなんて思ってない。<br>
        ただ、自分らしく生きたいだけ。<br>
        そのために、世界の空気を少し、変える必要がある。
      </p>
      <p :style="pStyle" ref="bcText3">
        BLASTは、あらゆる女性の生き方によりそうサービスやプロダクトを提供することで、<br>
        女性が本来もつ可能性を切り拓き、心地よく、自分のままに生きていける世界を実現します。
      </p>
      <p :style="pStyle" ref="bcText4">
        さあ、あなたの人生を、あなたのものに。
      </p>

    </div>
  </div>
</template>

<script>
import Common from './common.js'

export default {
  data() {
    return {
      w: 0,
      showTitle: false,
      showText0: false,
      showText1: false,
      showText2: false,
      showText3: false,
      showText4: false
    }
  },
  computed: {
    h1Style() {
      let fontSize = Math.min(60, this.w * 0.048)
      return {
        fontSize: fontSize + 'px'
      }
    },
    pStyle() {
      let fontSize = Math.min(18, this.w * 0.0175)
      return {
        fontSize: fontSize + 'px'
      }
    }
  },
  methods: {
    resized() {
      this.w = window.innerWidth
    },
    scrolled() {
      if (!this.showTitle) {
        this.showTitle = Common.showElement(this.$refs.bcTitle, 0.7, 0)
      }
      if (!this.showText0) {
        this.showText0 = Common.showElement(this.$refs.bcText0, 0.7, 100)
      }
      if (!this.showText1) {
        this.showText1 = Common.showElement(this.$refs.bcText1, 0.7, 200)
      }
      if (!this.showText2) {
        this.showText2 = Common.showElement(this.$refs.bcText2, 0.7, 300)
      }
      if (!this.showText3) {
        this.showText3 = Common.showElement(this.$refs.bcText3, 0.7, 400)
      }
      if (!this.showText4) {
        this.showText4 = Common.showElement(this.$refs.bcText4, 0.7, 500)
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.resized)
    setTimeout(() => {
      this.resized()
    }, 200)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resized)
  }
}
</script>

<style scoped lang="scss">
@import "scoped.scss";

.page-home-statement {
  width: 100%;
  padding: 136px 0 96px;
  display: flex;
  justify-content: center;

  .bc-content {
    width: 100%;
    padding: 0 24px 128px;

    color: $bc-color-green;
    text-align: center;

    h1 {
      padding: 48px 0;
      font-weight: normal;

      opacity: 0.0;
      transform: scale(1.05);
      transition: opacity 1.2s, transform 1.2s;
      &.show {
        opacity: 1.0;
        transform: scale(1.0);
      }
    }
    p {
      margin: 0 0 2em;
      line-height: 1.8em;

      opacity: 0.0;
      transform: scale(1.05);
      transition: opacity 1.2s, transform 1.2s;
      &.show {
        opacity: 1.0;
        transform: scale(1.0);
      }
    }
  }
}
</style>
