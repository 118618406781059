<template>
  <div class="page-home-business">

    <div class="bc-title-row" ref="bcTitleRow">
      <h1 :style="h1Style">HOW WE EMPOWER</h1>
      <p :style="titlePStyle">
        私たちは３つの事業で、女性たちのライフスタイルを<br>
        エンパワーします
      </p>
    </div>

    <div class="bc-business">
      <div class="bc-business-row">
        <div class="bc-business-col" ref="bcBusinessCol0">
          <div class="bc-image">
            <img src="./res/home_products.svg" :style="imgStyle" />
          </div>
          <div class="bc-business-title">
            <h3 class="bc-number">01.</h3>
            <h3 :style="h3Style">products</h3>
          </div>
          <p :style="pStyle">あたらしい選択肢を提案する商品開発</p>
        </div>
        <div class="bc-business-col" ref="bcBusinessCol1">
          <div class="bc-image">
            <img src="./res/home_media.svg" :style="imgStyle" />
          </div>
          <div class="bc-business-title">
            <h3 class="bc-number">02.</h3>
            <h3 :style="h3Style">media</h3>
          </div>
          <p :style="pStyle">あらたな気づきや知識を届ける情報発信</p>
        </div>
        <div class="bc-business-col" ref="bcBusinessCol2">
          <div class="bc-image">
            <img src="./res/home_community.svg" :style="imgStyle" />
          </div>
          <div class="bc-business-title">
            <h3 class="bc-number">03.</h3>
            <h3 :style="h3Style">community</h3>
          </div>
          <p :style="pStyle">
            女性同士が互いにエンパワーできる<br>
            コミュニティの提供
          </p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Common from './common.js'

export default {
  data() {
    return {
      w: 0,
      show: false,
      showTitleRow: false,
      showBusinessCol0: false,
      showBusinessCol1: false,
      showBusinessCol2: false
    }
  },
  computed: {
    h1Style() {
      let fontSize = Math.min(116, this.w * 0.085)
      return {
        fontSize: fontSize + 'px'
      }
    },
    titlePStyle() {
      let fontSize = Math.min(22, this.w * 0.02)
      return {
        fontSize: fontSize + 'px'
      }
    },
    h3Style() {
      let fontSize = Math.min(40, this.w * 0.03)
      return {
        fontSize: fontSize + 'px'
      }
    },
    pStyle() {
      let fontSize = Math.min(14, this.w * 0.0125)
      return {
        fontSize: fontSize + 'px'
      }
    },
    imgStyle() {
      let h = this.w * 0.086
      return {
        height: h + 'px'
      }
    }
  },
  methods: {
    resized() {
      this.w = window.innerWidth
    },
    scrolled() {
      if (!this.show) {
        this.show = Common.showElement(this.$el, 0.1, 0)
      }
      if (!this.showTitleRow) {
        this.showTitleRow = Common.showElement(this.$refs.bcTitleRow, 0.5, 200)
      }
      if (!this.showBusinessCol0) {
        this.showBusinessCol0 = Common.showElement(this.$refs.bcBusinessCol0, 0.5, 200)
      }
      if (!this.showBusinessCol1) {
        this.showBusinessCol1 = Common.showElement(this.$refs.bcBusinessCol1, 0.5, 200)
      }
      if (!this.showBusinessCol2) {
        this.showBusinessCol2 = Common.showElement(this.$refs.bcBusinessCol2, 0.5, 200)
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.resized)
    setTimeout(() => {
      this.resized()
    }, 200)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resized)
  }
}
</script>

<style scoped lang="scss">
@import "scoped.scss";

.page-home-business {
  position: relative;
  width: 100%;
  padding: 96px 0;
  background-color: $bc-color-green;

  opacity: 0.0;
  transition: opacity 1.2s;
  &.show {
    opacity: 1.0;
  }

  .bc-title-row {
    position: relative;

    transform: scale(1.1);
    opacity: 0.0;
    transition: opacity 1.2s, transform 1.2s;
    &.show {
      transform: scale(1.0);
      opacity: 1.0;
    }

    h1 {
      text-align: center;
      @include font-nimbus-sans-extd();
      color: $bc-color-light-green;
      opacity: 0.25;
    }
    p {
      position: absolute;
      text-align: center;
      bottom: 0;
      width: 100%;
      margin: 0;
      padding: 0;
      color: white;
    }
  }

  .bc-business {
    width: 100%;
    margin: 24px 0 0;
    display: flex;
    justify-content: center;

    .bc-business-row {
      width: 100%;
      display: flex;
      max-width: 1024px;
      justify-content: space-around;

      .bc-business-col {
        width: 30%;
        padding: 40px 24px 24px;

        opacity: 0.0;
        transition: opacity 1.2s;
        &.show {
          opacity: 1.0;
        }

        .bc-image {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            display: block;
          }
        }
        .bc-business-title {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          h3 {
            padding: 40px 0 0;
            text-align: center;
            color: white;
            @include font-clearface();
            letter-spacing: 0.025em;
            &.bc-number {
              padding: 12px 8px 0 0;
              @include font-nimbus-sans-extd();
              font-size: 16px;
            }
          }
        }
        p {
          text-align: center;
          color: #ffffff;
          line-height: 2em;
        }
      }
    }
  }
}
</style>
