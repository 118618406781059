<template>
  <div class="page-home-founder">
    <h1 :style="h1Style">FOUNDER</h1>


    <div class="bc-content-row">
      <div class="bc-content">
        <div class="bc-image">
          <img src="./res/home_founder.png" />
        </div>

        <div class="bc-texts">
          <h3>石井リナ / <span class="bc-span">RINA ISHII</span></h3>
          <p>
            新卒でIT系広告代理店に入社し、企業に対するデジタルマーケティング支援に従事。
            2018年に起業し、BLAST Inc.を創業。
            フェムテックブランド「Nagi」とエンパワーメントメディア「BLAST」の立ち上げ、運営を行う。
            2019年に日本を代表するビジョンや才能を持った30歳未満の30人を表彰する「Forbes 30 Under 30」インフルエンサー部門を受賞。
          </p>
        </div>
      </div>
    </div>


    <!-- <div class="bc-view-all-row">
         <bc-link-button
         :mobile="false"
         href="/members"
         title="VIEW ALL MEMBERS"
         />
         </div> -->


  </div>
</template>

<script>
import Common from './common.js'
import BcLinkButton from './bc_link_button.vue'

export default {
  data() {
    return {
      w: 0,
      show: false
    }
  },
  components: {
    'bc-link-button': BcLinkButton
  },
  computed: {
    h1Style() {
      let fontSize = Math.min(170, this.w * 0.15)
      return {
        fontSize: fontSize + 'px',
      }
    }
  },
  methods: {
    resized() {
      this.w = window.innerWidth
    },
    scrolled() {
      if (!this.show) {
        this.show = Common.showElement(this.$el, 0.5, 0)
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.resized)
    setTimeout(() => {
      this.resized()
    }, 200)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resized)
  }
}
</script>

<style scoped lang="scss">
@import "scoped.scss";

.page-home-founder {
  position: relative;
  margin: 0 0 128px;
  width: 100%;

  opacity: 0.0;
  transition: opacity 1.2s;
  &.show {
    opacity: 1.0;
  }

  h1 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    text-align: center;
    z-index: 10;
    @include font-nimbus-sans-extd();
    color: white;
  }

  .bc-content-row {
    position: relative;
    width: 100%;
    padding: 128px 0 24px;
    z-index: 20;
    display: flex;
    justify-content: center;

    .bc-content {
      width: 100%;
      max-width: 980px;
      display: flex;
      justify-content: center;
      align-items: center;
      .bc-image {
        width: 51%;
        max-width: calc(400px + 48px);
        padding: 24px;
        img {
          width: 100%;
        }
      }
      .bc-texts {
        width: 60%;
        padding: 0 24px 0 46px;
        h3 {
          width: 100%;
          padding: 0 0 16px;
          text-align: center;
          font-size: 24px;
          font-weight: normal;
          .bc-span {
            @include font-nimbus-sans-extd();
            font-size: 26px;
            font-weight: bold;
          }
        }
        p {
          font-size: 13px;
          line-height: 2em;
        }
      }
    }
  }
  .bc-view-all-row {
    position: absolute;
    z-index: 30;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
