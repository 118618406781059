import { render, staticRenderFns } from "./bc_header_menu.vue?vue&type=template&id=3d533f70&scoped=true&"
import script from "./bc_header_menu.vue?vue&type=script&lang=js&"
export * from "./bc_header_menu.vue?vue&type=script&lang=js&"
import style0 from "./bc_header_menu.vue?vue&type=style&index=0&id=3d533f70&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d533f70",
  null
  
)

export default component.exports