<template>
  <div class="bc-link-button-row">
    <a
      :href="href"
      :style="buttonStyle"
    >{{ title }}</a>
  </div>
</template>

<script>
export default {
  props: [
    'mobile',
    'href',
    'title'
  ],
  computed: {
    buttonStyle() {
      if (this.mobile) {
        return {
          width: '200px',
          height: '38px',
          fontSize: '12px',
          lineHeight: '38px',
          borderRadius: '19px'
        }
      }
      return {
        width: '260px',
        height: '44px',
        fontSize: '16px',
        lineHeight: '44px',
        borderRadius: '24px'
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "scoped.scss";
.bc-link-button-row {
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    display: block;
    color: $bc-color-beige;
    background-color: $bc-color-red;
    text-align: center;
    @include font-nimbus-sans-extd();
  }
}


</style>
