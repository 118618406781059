<template>
  <div class="bc-loading-view" ref="bcLoadingView">
    <h1 v-if="show">
      loading...
      <div />
    </h1>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true
    }
  },
  methods: {
    showView() {
      this.$refs.bcLoadingView.style.display = 'flex'
      this.$refs.bcLoadingView.classList.remove('hide')
      this.show = true
    },
    hideView() {
      this.show = false
      this.$refs.bcLoadingView.classList.add('hide')
      setTimeout(() => {
        this.$refs.bcLoadingView.style.display = 'none'
      }, 1000)
    }
  }
}
</script>

<style scoped lang="scss">
@import "scoped.scss";

.bc-loading-view {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $bc-color-bg-white;

  opacity: 1.0;
  transition: opacity 1.2s;
  &.hide {
    opacity: 0.0;
  }

  h1 {
    position: relative;
    padding: 0 0 24px;
    @include font-clearface-bold();
    font-weight: normal;
    font-size: 32px;
    color: $bc-color-red;

    div {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $bc-color-bg-white;

      animation-name: loading;
      animation-duration: 1.0s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
  }

  @keyframes loading {
    0% {
      left: 0%;
      width: 100%;
    }
    20% {
      left: 0%;
      width: 100%;
    }
    70% {
      left: 100%;
      width: 0%;
    }
    100% {
      left: 100%;
      width: 0%;
    }
  }
}
</style>
