<template>
  <v-dialog v-model="show" :width="dialogWidth" persistent>
    <v-card class="bc-message-dialog">

      <v-card-title class="bc-title">
        <img src="./res/logo.svg" />
      </v-card-title>

      <v-card-text>
        <p v-html="message" class="bc-message" />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed @click="okButtonClicked">OK</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: [
    'width'
  ],
  data() {
    return {
      show: false,
      message: 'message',
      tag: 0
    }
  },
  computed: {
    dialogWidth() {
      if (this.width) {
        return this.width
      }
      return '320px'
    }
  },
  methods: {
    showDialog(message, tag) {
      this.message = message
      this.tag = tag
      this.show = true
    },
    okButtonClicked() {
      this.$emit('ok-button-clicked', this.tag)
      this.show = false
    }
  }
}
</script>

<style scoped lang="scss">
@import "scoped.scss";

.bc-message-dialog {
  .bc-message {
    padding: 16px 0 0;
    white-space: pre-line;
  }
}
</style>
