<template>
  <div class="page-home-founder">

    <h1 :style="h1Style">FOUNDER</h1>

    <div class="bc-image">
      <img src="./res/home_founder.png" />
    </div>

    <h3>石井リナ / <span class="bc-span">RINA ISHII</span></h3>
    <p>
      新卒でIT系広告代理店に入社し、企業に対するデジタルマーケティング支援に従事。
      2018年に起業し、BLAST Inc.を創業。
      フェムテックブランド「Nagi」とエンパワーメントメディア「BLAST」の立ち上げ、運営を行う。
      2019年に日本を代表するビジョンや才能を持った30歳未満の30人を表彰する「Forbes 30 Under 30」インフルエンサー部門を受賞。
    </p>

    <!-- <div class="bc-view-all-row">
         <bc-link-button
         :mobile="true"
         href="/members"
         title="VIEW ALL MEMBERS"
         />
         </div> -->

  </div>
</template>

<script>
import Common from './common.js'
import BcLinkButton from './bc_link_button.vue'

export default {
  data() {
    return {
      w: 0
    }
  },
  components: {
    'bc-link-button': BcLinkButton
  },
  computed: {
    h1Style() {
      let fontSize = this.w * 0.1625
      return {
        fontSize: fontSize + 'px',
      }
    }
  },
  methods: {
    resized() {
      this.w = window.innerWidth
    },
    scrolled() {
      if (!this.show) {
        this.show = Common.showElement(this.$el, 0.2, 0)
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.resized)
    setTimeout(() => {
      this.resized()
    }, 200)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resized)
  }
}
</script>

<style scoped lang="scss">
@import "scoped.scss";

.page-home-founder {
  position: relative;
  margin: 96px 0 32px;
  width: 100%;
  padding: 0 0 92px;

  opacity: 0.0;
  transition: opacity 1.2s;
  &.show {
    opacity: 1.0;
  }

  h1 {
    position: absolute;
    left: 0;
    top: -0.45em;
    width: 100%;
    text-align: center;
    @include font-nimbus-sans-extd();
    line-height: 1em;
    color: white;
  }

  .bc-image {
    position: relative;
    width: 100%;
    padding: 24px;
    img {
      width: 100%;
    }
  }

  h3 {
    width: 100%;
    padding: 0 0 20px;
    text-align: center;
    font-size: 18px;
    font-weight: normal;
    .bc-span {
      @include font-nimbus-sans-extd();
      font-size: 20px;
      font-weight: bold;
    }
  }
  p {
    padding: 0 16px;
    font-size: 13px;
    line-height: 1.75em;
    text-align: justify;
  }
  .bc-view-all-row {
    padding: 24px 0 64px;
  }
}
</style>
