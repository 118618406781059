<template>
  <div class="page-home-services-m">
    <h1>service</h1>

    <div class="bc-row left" ref="bcRowLeft">
      <div class="bc-image">
        <a href="https://nagi-jp.com/">
          <img src="./res/home_services_nagi.png" />
        </a>
      </div>
      <div class="bc-texts">
        <h4>PRODUCTS</h4>
        <div class="bc-logo nagi">
          <a href="https://nagi-jp.com/">
            <img src="./res/nagi_logo.svg" />
          </a>
        </div>
        <h3>あなたを自由にする下着</h3>
        <p>Nagiは、わたしたちの身体ともっとうまく付き合うために生まれた、１枚でも過ごせる吸水ショーツです。</p>
        <p>
          <a href="https://www.instagram.com/nagi_official_jp/" target="_blank">
            <img src="./res/icon_instagram.svg">
          </a>
          <a href="https://twitter.com/Nagi_officialJP" target="_blank">
            <img src="./res/icon_twitter.svg">
          </a>
        </p>
      </div>
    </div>

    <div class="bc-row right" ref="bcRowRight">
      <div class="bc-image right">
        <img src="./res/home_services_blast.png" />
      </div>
      <div class="bc-texts right">
        <h4>MEDIA</h4>
        <div class="bc-logo blast">
          <img src="./res/blast_logo.png" />
        </div>
        <h3>女性たちにむけた<br>エンパワーメントメディア</h3>
        <p>BLASTは社会問題からセックスまで、多様な選択肢を提案するエンパワーメントメディアです。</p>
        <p>
          <a href="https://www.instagram.com/blast.jp/" target="_blank">
            <img src="./res/icon_instagram.svg">
          </a>
          <a href="https://twitter.com/BLAST___JP" target="_blank">
            <img src="./res/icon_twitter.svg">
          </a>
        </p>
      </div>
    </div>

    <div class="bc-coming-soon" ref="bcComingSoon">
      <div class="bc-circle-row">
        <h4>COMMUNITY</h4>
      </div>
      <h3>coming soon...</h3>
    </div>

  </div>
</template>

<script>
import Common from './common.js'

export default {
  data() {
    return {
      w: 0,
      show: false,
      showRowLeft: false,
      showRowRight: false,
      showComingSoon: false
    }
  },
  methods: {
    resized() {
      this.w = window.innerWidth
    },
    scrolled() {
      if (!this.show) {
        this.show = Common.showElement(this.$el, 0.2, 0)
      }
      if (!this.showRowLeft) {
        this.showRowLeft = Common.showElement(this.$refs.bcRowLeft, 0.2, 0)
      }
      if (!this.showRowRight) {
        this.showRowRight = Common.showElement(this.$refs.bcRowRight, 0.2, 0)
      }
      if (!this.showComingSoon) {
        this.showComingSoon = Common.showElement(this.$refs.bcComingSoon, 0.2, 0)
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.resized)
    setTimeout(() => {
      this.resized()
    }, 200)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resized)
  }
}
</script>

<style scoped lang="scss">
@import "scoped.scss";

.page-home-services-m {
  width: 100%;
  padding: 96px 0 88px;
  overflow: hidden;

  opacity: 0.0;
  transition: opacity 1.2s;
  &.show {
    opacity: 1.0;
  }

  h1 {
    padding: 0 0 24px;
    text-align: center;
    @include font-clearface-bold();
    font-size: 40px;
    font-weight: normal;
    color: $bc-color-green;
    letter-spacing: 0;
  }

  .bc-row {
    width: 100%;

    &.left {
      transform: translateX(-10px);
      opacity: 0.0;
      transition: opacity 1.2s, transform 1.2s;
      &.show {
        transform: translateX(0);
        opacity: 1.0;
      }
    }
    &.right {
      margin: 10px 0 0;

      transform: translateX(10px);
      opacity: 0.0;
      transition: opacity 1.2s, transform 1.2s;
      &.show {
        transform: translateX(0);
        opacity: 1.0;
      }
    }

    .bc-image {
      width: 86%;
      padding: 16px 16px 10px;
      &.right {
        margin-left: 14%;
        padding: 16px 16px 0;
      }
      img {
        width: 100%;
      }
    }

    .bc-texts {
      width: 88%;
      padding: 0 12px 0 24px;
      &.right {
        margin-left: 12%;
      }

      h4 {
        @include font-nimbus-sans-extd();
        color: $bc-color-green;
        font-size: 12px;
      }
      h3 {
        font-size: 15.5px;
        font-weight: normal;
        padding: 0 0 12px;
      }
      .bc-logo {
        padding: 12px 0;
        &.nagi {
          width: 48%;
        }
        &.blast {
          width: 70%;
          padding: 7px 0 12px;
        }
        img {
          width: 100%;
        }
      }
      p {
        font-size: 13px;
        text-align: justify;
        line-height: 1.75em;
        margin: 0 0 5px;
        a {
          img {
            margin: 8px 10px 8px 0;
            width: 22px;
            height: 22px;
            padding: 1px;
            object-fit: contain;
          }
        }
      }
    }
  }

  .bc-coming-soon {
    width: 100%;
    padding: 48px 0 0;

    opacity: 0.0;
    transition: opacity 1.2s;
    &.show {
      opacity: 1.0;
    }

    .bc-circle-row {
      display: flex;
      justify-content: center;
      h4 {
        width: 156px;
        height: 156px;
        text-align: center;
        @include font-nimbus-sans-extd();
        line-height: 156px;
        border-radius: 50%;
        color: $bc-color-green;
        background-color: $bc-color-light-green;
        font-size: 12px;
      }
    }
    h3 {
      padding: 12px 0 0;
      text-align: center;
      @include font-clearface();
      font-size: 28px;
      font-weight: bold;
      letter-spacing: -0.05em;
      color: $bc-color-green;
    }
  }
}
</style>
