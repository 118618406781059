<template>
  <v-app class="page-home">
    <bc-header ref="bcHeader" />

    <template v-if="isMobile">
      <page-home-hero-m ref="bcHomeHero" />
      <page-home-statement-m ref="bcStatement" />
      <page-home-business-m ref="bcBusiness" />
      <page-home-services-m ref="bcService" />
      <!-- news、media 稼働時にONにする場所 >>> page_home.vue / bc_header_menu.vue / bc_footer{,_m}.vue -->
      <!-- <page-home-news-m :news="news" ref="bcNews" v-if="news" />
           <page-home-media-m :media="media" ref="bcMedia" v-if="media" /> -->
      <page-home-founder-m ref="bcFounder" />
      <!-- <page-home-blog-m :blog="blog" ref="bcBlog" v-if="blog" /> -->
      <!-- <page-home-join-us-m ref="bcJoinUs" /> -->
      <page-home-contact-m ref="bcContact" />
      <bc-footer-m />
    </template>

    <template v-if="!isMobile">
      <page-home-hero ref="bcHomeHero" />
      <page-home-statement ref="bcStatement" />
      <page-home-business ref="bcBusiness" />
      <page-home-services ref="bcService" />
      <!-- <page-home-news :news="news" ref="bcNews" v-if="news" />
           <page-home-media :media="media" ref="bcMedia" v-if="media" /> -->
      <page-home-founder ref="bcFounder" />
      <!-- <page-home-blog :blog="blog" ref="bcBlog" v-if="blog" /> -->
      <!-- <page-home-join-us ref="bcJoinUs" /> -->
      <page-home-contact ref="bcContact" />
      <bc-footer />
    </template>

    <bc-loading-view ref="bcLoadingView" />

  </v-app>
</template>

<script>
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import Common from './common.js'
import BcHeader from './bc_header.vue'
import PageHomeHeroM from './page_home_hero_m.vue'
import PageHomeStatementM from './page_home_statement_m.vue'
import PageHomeBusinessM from './page_home_business_m.vue'
import PageHomeServicesM from './page_home_services_m.vue'
/* import PageHomeNewsM from './page_home_news_m.vue'
 * import PageHomeMediaM from './page_home_media_m.vue' */
/* import PageHomeBlogM from './page_home_blog_m.vue' */
import PageHomeFounderM from './page_home_founder_m.vue'
/* import PageHomeJoinUsM from './page_home_join_us_m.vue' */
import PageHomeContactM from './page_home_contact_m.vue'
import BcFooterM from './bc_footer_m.vue'

import PageHomeHero from './page_home_hero.vue'
import PageHomeStatement from './page_home_statement.vue'
import PageHomeBusiness from './page_home_business.vue'
import PageHomeServices from './page_home_services.vue'
/* import PageHomeNews from './page_home_news.vue'
 * import PageHomeMedia from './page_home_media.vue' */
/* import PageHomeBlog from './page_home_blog.vue' */
import PageHomeFounder from './page_home_founder.vue'
/* import PageHomeJoinUs from './page_home_join_us.vue' */
import PageHomeContact from './page_home_contact.vue'
import BcFooter from './bc_footer.vue'

import BcLoadingView from './bc_loading_view.vue'

Vue.use(Vuetify)
const vuetify = new Vuetify({
  theme: {
    light: true,
    themes: {
      light: {
        primary: '#E94A1F',
        error: '#E94A1F'
      }
    }
  }
})

export default {
  vuetify,
  components: {
    'bc-header': BcHeader,

    'page-home-hero-m': PageHomeHeroM,
    'page-home-statement-m': PageHomeStatementM,
    'page-home-business-m': PageHomeBusinessM,
    'page-home-services-m': PageHomeServicesM,
    /* 'page-home-news-m': PageHomeNewsM,
     * 'page-home-media-m': PageHomeMediaM, */
    /*     'page-home-blog-m': PageHomeBlogM, */
    'page-home-founder-m': PageHomeFounderM,
    /*     'page-home-join-us-m': PageHomeJoinUsM, */
    'page-home-contact-m': PageHomeContactM,
    'bc-footer-m': BcFooterM,

    'page-home-hero': PageHomeHero,
    'page-home-statement': PageHomeStatement,
    'page-home-business': PageHomeBusiness,
    'page-home-services': PageHomeServices,
    /* 'page-home-news': PageHomeNews,
     * 'page-home-media': PageHomeMedia, */
    /*     'page-home-blog': PageHomeBlog, */
    'page-home-founder': PageHomeFounder,
    /*     'page-home-join-us': PageHomeJoinUs, */
    'page-home-contact': PageHomeContact,
    'bc-footer': BcFooter,

    'bc-loading-view': BcLoadingView
  },
  data() {
    return {
      isMobile: true,
      news: null,
      media: null,
      blog: null
    }
  },
  methods: {
    resized() {
      this.isMobile = Common.isMobile()
    },
    scrolled() {
      if (!this.$refs.bcLoadingView || this.$refs.bcLoadingView.show) {
        return
      }
      if (this.$refs.bcHeader) {
        this.$refs.bcHeader.scrolled()
      }
      if (this.$refs.bcHomeHero) {
        this.$refs.bcHomeHero.scrolled()
      }
      if (this.$refs.bcStatement) {
        this.$refs.bcStatement.scrolled()
      }
      if (this.$refs.bcBusiness) {
        this.$refs.bcBusiness.scrolled()
      }
      if (this.$refs.bcService) {
        this.$refs.bcService.scrolled()
      }
      /* if (this.$refs.bcNews) {
       *   this.$refs.bcNews.scrolled()
       * }
       * if (this.$refs.bcMedia) {
       *   this.$refs.bcMedia.scrolled()
       * } */
      if (this.$refs.bcFounder) {
        this.$refs.bcFounder.scrolled()
      }
      /* if (this.$refs.bcBlog) {
       *   this.$refs.bcBlog.scrolled()
       * } */
      /* if (this.$refs.bcJoinUs) {
       *   this.$refs.bcJoinUs.scrolled()
       * } */
      if (this.$refs.bcContact) {
        this.$refs.bcContact.scrolled()
      }
    },
    hashChanged() {
      if (location.hash == '#about') {
        this.$vuetify.goTo(0)
      }
      if (location.hash == '#statement') {
        let offset = this.isMobile ? -48 : -64
        this.$vuetify.goTo(this.$refs.bcStatement, { offset: offset })
      }
      if (location.hash == '#service') {
        let offset = this.isMobile ? -48 : -32
        this.$vuetify.goTo(this.$refs.bcService, { offset: offset })
      }
      if (location.hash == '#founder') {
        let offset = this.isMobile ? 80 : 12
        this.$vuetify.goTo(this.$refs.bcFounder, { offset: offset })
      }
      if (location.hash == '#contact') {
        let offset = this.isMobile ? 48 : 64
        this.$vuetify.goTo(this.$refs.bcContact, { offset: offset })
      }
      location.hash = ''
    },
    getHome() {
      Common.axios().get('/home.json').then(response => {
        console.log(response)
        /* this.news = response.data.news
         * this.media = response.data.media */
        /*         this.blog = response.data.blog */
        setTimeout(() => {
          this.$refs.bcLoadingView.hideView()
          setTimeout(() => {
            this.resized()
            this.scrolled()
          }, 600)
        }, 200)
      }).catch(error => {
        console.log(error)
      })
    }
  },
  mounted() {
    Common.validateTokenInPreview(document, location.pathname, this.getHome)
    window.addEventListener('resize', this.resized)
    setTimeout(() => {
      this.resized()
    }, 200)
    window.addEventListener('scroll', this.scrolled)
    window.addEventListener("hashchange", this.hashChanged)
    setTimeout(() => {
      this.hashChanged()
    }, 1000)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resized)
    window.removeEventListener('scrolled', this.scrolled)
    window.removeEventListener('hashchange', this.hashChanged)
  }
}
</script>

<style lang="scss">
@import "global.scss";
</style>

<style scoped lang="scss">
@import "scoped.scss";

.page-home {
  width: 100%;
  background-color: $bc-color-bg-cream;
}
</style>
