<template>
  <div class="page-home-services">
    <div class="bc-content">
      <h1>service</h1>


      <div class="bc-row left" ref="bcRowLeft">
        <div class="bc-image left">
          <a href="https://nagi-jp.com/">
            <img src="./res/home_services_nagi.png" />
          </a>
        </div>
        <div class="bc-texts">
          <h4>PRODUCTS</h4>
          <div class="bc-logo nagi">
            <a href="https://nagi-jp.com/">
              <img class="nagi-img" src="./res/nagi_logo.svg" />
            </a>
          </div>
          <h3>あなたを自由にする下着</h3>
          <p>Nagiは、わたしたちの身体ともっとうまく付き合うために生まれた、１枚でも過ごせる吸水ショーツです。</p>
          <p>
            <a href="https://www.instagram.com/nagi_official_jp/" target="_blank">
              <img src="./res/icon_instagram.svg">
            </a>
            <a href="https://twitter.com/Nagi_officialJP" target="_blank">
              <img src="./res/icon_twitter.svg">
            </a>
          </p>
        </div>
      </div>


      <div class="bc-row right" ref="bcRowRight">
        <div class="bc-texts">
          <h4>MEDIA</h4>
          <div class="bc-logo blast">
            <img class="blast-img" src="./res/blast_logo.png" />
          </div>
          <h3>女性たちにむけたエンパワーメントメディア</h3>
          <p>BLASTは社会問題からセックスまで、多様な選択肢を提案するエンパワーメントメディアです。</p>
          <p>
            <a href="https://www.instagram.com/blast.jp/" target="_blank">
              <img src="./res/icon_instagram.svg">
            </a>
            <a href="https://twitter.com/BLAST___JP" target="_blank">
              <img src="./res/icon_twitter.svg">
            </a>
          </p>
        </div>
        <div class="bc-image right">
          <img src="./res/home_services_blast.png" />
        </div>
      </div>


      <div class="bc-row-coming-soon" ref="bcComingSoon">
        <div class="bc-row-content">

          <h4>COMMUNITY</h4>
          <h3>coming soon...</h3>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import Common from './common.js'

export default {
  data() {
    return {
      w: 0,
      show: false,
      showRowLeft: false,
      showRowRight: false,
      showComingSoon: false
    }
  },
  methods: {
    resized() {
      this.w = window.innerWidth
    },
    scrolled() {
      if (!this.show) {
        this.show = Common.showElement(this.$el, 0.2, 0)
      }
      if (!this.showRowLeft) {
        this.showRowLeft = Common.showElement(this.$refs.bcRowLeft, 0.5, 0)
      }
      if (!this.showRowRight) {
        this.showRowRight = Common.showElement(this.$refs.bcRowRight, 0.5, 0)
      }
      if (!this.showComingSoon) {
        this.showComingSoon = Common.showElement(this.$refs.bcComingSoon, 0.5, 0)
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.resized)
    setTimeout(() => {
      this.resized()
    }, 200)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resized)
  }
}
</script>

<style scoped lang="scss">
@import "scoped.scss";

.page-home-services {
  width: 100%;
  padding: 96px 0 128px;
  display: flex;
  justify-content: center;

  opacity: 0.0;
  transition: opacity 1.2s;
  &.show {
    opacity: 1.0;
  }

  .bc-content {
    width: 100%;
    max-width: 1280px;

    h1 {
      text-align: center;
      @include font-clearface-bold();
      font-weight: normal;
      font-size: 76px;
      color: $bc-color-green;
    }

    .bc-row {
      width: 100%;
      display: flex;
      align-items: center;
      &.left {
        justify-content: flex-start;

        transform: translateX(24px);
        opacity: 0.0;
        transition: opacity 1.2s, transform 1.2s;
        &.show {
          transform: translateX(0);
          opacity: 1.0;
        }
      }
      &.right {
        justify-content: flex-end;

        transform: translateX(-24px);
        opacity: 0.0;
        transition: opacity 1.2s, transform 1.2s;
        &.show {
          transform: translateX(0);
          opacity: 1.0;
        }
      }

      .bc-image {
        width: 42.5%;
        &.left {
          max-width: calc(450px + 60px + 24px);
          padding: 24px 60px 24px 24px;
        }
        &.right {
          max-width: calc(450px + 50px + 24px);
          padding: 24px 24px 24px 50px;
        }
        img {
          width: 100%;
        }
      }
      .bc-texts {
        width: 35%;
        h4 {
          @include font-nimbus-sans-extd();
          color: $bc-color-green;
          font-size: 14px;
        }
        h3 {
          font-size: 16px;
          font-weight: normal;
          padding: 0 0 12px;
        }
        .bc-logo {
          &.nagi {
            padding: 20px 0 16px;
            width: 45.18%;
          }
          &.blast {
            padding: 15px 0 20px;
            width: 61%;
          }
          .nagi-img {
            width: 100%;
            max-width: 200px;
          }
          .blast-img {
            width: 100%;
            max-width: 270px;
          }
        }
        p {
          font-size: 14px;
          a {
            img {
              margin: 8px 10px 8px 0;
              padding: 1px;
              height: 22px;
              object-fit: contain;
            }
          }
        }
      }
    }


    .bc-row-coming-soon {
      width: 100%;
      padding: 48px 0;
      display: flex;
      justify-content: center;

      opacity: 0.0;
      transition: opacity 1.2s;
      &.show {
        opacity: 1.0;
      }

      .bc-row-content {
        width: 80%;
        max-width: 800px;
        display: flex;
        h4 {
          width: 250px;
          height: 250px;
          margin: 0 50px 0 0;
          text-align: center;
          @include font-nimbus-sans-extd();
          line-height: 250px;
          border-radius: 50%;
          font-size: 14px;
          color: $bc-color-green;
          background-color: $bc-color-light-green;
        }
        h3 {
          @include font-clearface-bold();
          font-size: 50px;
          font-weight: normal;
          line-height: 250px;
          color: $bc-color-green;
        }
      }
    }
  }
}
</style>
