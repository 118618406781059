<template>
  <footer>
    <bc-wave color="#E94A1F" background-color="#F5F0E9" />

    <div class="bc-footer-container">
      <div class="bc-footer-content">

        <a href="/" class="bc-logo">
          <img src="./res/logo_white.svg" />
        </a>

        <div class="bc-links">
          <div class="bc-links-row">
            <a :style="linkStyle" href="/#statement">STATEMENT</a>
            <a :style="linkStyle" href="/#service">SERVICE</a>
            <!-- <a :style="linkStyle" href="/news">NEWS</a>
                 <a :style="linkStyle" href="/media">MEDIA</a> -->
            <!-- <a :style="linkStyle" href="/members">MEMBERS</a> -->
            <a :style="linkStyle" href="/#founder">FOUNDER</a>
            <!-- <a :style="linkStyle" href="/blog">BLOG</a> -->
            <a :style="linkStyle" href="/#contact">CONTACT</a>
          </div>
          <div class="bc-terms-row">
            <a :style="termStyle" href="/pages/privacy_policy">プライバシーポリシー</a>
            <a :style="termStyle" href="/pages/terms_of_service">サービス利用規約</a>
            <a :style="termStyle" href="https://nagi-jp.com/pages/law">特定取引法に基づく表記</a>
          </div>
          <p :style="copyrightStyle" class="bc-copyright" >&copy;2023 BLAST Inc.</p>
        </div>

      </div>
    </div>

  </footer>
</template>

<script>
import BcWave from './bc_wave.vue'

export default {
  components: {
    'bc-wave': BcWave,
  },
  data() {
    return {
      isBottom: false,
      w: 0
    }
  },
  computed: {
    linkStyle() {
      let fontSize = Math.min(13, this.w * 0.0125)
      return {
        fontSize: fontSize + 'px'
      }
    },
    termStyle() {
      let fontSize = Math.min(10, this.w * 0.0115)
      return {
        fontSize: fontSize + 'px'
      }
    },
    copyrightStyle() {
      let fontSize = Math.min(10, this.w * 0.0115)
      return {
        fontSize: fontSize + 'px'
      }
    }
  },
  watch: {
    isBottom(newVal, oldVal) {
      if (newVal) {
        document.body.style.backgroundColor = '#E94A1F'
      } else {
        document.body.style.backgroundColor = '#F5F0E9'
      }
    }
  },
  methods: {
    resized() {
      this.w = window.innerWidth
    },
    scrolled() {
      this.isBottom = (document.body.clientHeight - 128 <= window.scrollY + window.innerHeight)
    }
  },
  mounted() {
    window.addEventListener('resize', this.resized)
    window.addEventListener('scroll', this.scrolled)
    setTimeout(() => {
      this.resized()
    }, 200)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrolled)
    window.removeEventListener('resize', this.resized)
  }
}
</script>

<style scoped lang="scss">
@import "scoped.scss";

footer {
  position: relative;
  width: 100%;
  background-color: $bc-color-red;

  .bc-footer-container {
    width: 100%;
    background-color: $bc-color-red;
    display: flex;
    justify-content: center;

    .bc-footer-content {
      width: 100%;
      max-width: 1280px;
      margin: 0 24px 0;
      padding: 64px 0 100px;
      display: flex;
      justify-content: space-between;

      .bc-logo {
        width: 20%;
        max-width: 200px;
        display: block;
        img {
          width: 100%;
        }
      }

      .bc-links {
        .bc-links-row {
          display: flex;
          justify-content: flex-end;
          flex-wrap: wrap;
          a {
            padding: 0 0 24px 70px;
            text-align: right;
            white-space: nowrap;
            @include font-nimbus-sans-extd();
            display: block;
            color: white;
            &:visited {
              color: white;
            }
          }
        }
        .bc-terms-row {
          display: flex;
          justify-content: flex-end;
          a {
            margin: 0 0 0 24px;
            display: block;
            color: white;
            &:visited {
              color: white;
            }
          }
        }
        .bc-copyright {
          margin: 10px 0 0;
          text-align: right;
          color: white;
          @include font-nimbus-sans-extd();
        }
      }
    }
  }
}
</style>
