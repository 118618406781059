<template>
  <header class="bc-header" :style="headerStyle">

    <div class="bc-header-bg" :style="{ opacity: bgOpacity }" />

    <div class="bc-header-content" :style="headerContentStyle">
      <a href="/" class="bc-logo">
        <img src="./res/logo.svg" :style="logoImageStyle" />
      </a>
      <button class="bc-menu-button" @click="menuClicked">
        <img src="./res/icon_menu.svg" :style="iconStyle" />
      </button>
    </div>

    <bc-header-menu ref="bcHeaderMenu" />

  </header>
</template>

<script>
import Common from './common.js'
import BcHeaderMenu from './bc_header_menu.vue'

export default {
  components: {
    'bc-header-menu': BcHeaderMenu
  },
  data() {
    return {
      isMobile: true,
      bgOpacity: 0,
      showMenu: false
    }
  },
  computed: {
    headerStyle() {
      if (this.isMobile) {
        return {
          height: '48px'
        }
      }
      return {
        height: '64px'
      }
    },
    headerContentStyle() {
      if (this.isMobile) {
        return {
          paddingLeft: 16 + 'px',
          paddingRight: 8 + 'px'
        }
      }
      return {
        paddingLeft: 24 + 'px',
        paddingRight: 16 + 'px'
      }
    },
    logoImageStyle() {
      if (this.isMobile) {
        return {
          height: '20px'
        }
      }
      return {
        width: '170px'
      }
    },
    iconStyle() {
      if (this.isMobile) {
        return {
          width: '20px'
        }
      }
      return {
        width: '30px'
      }
    }
  },
  methods: {
    resized() {
      this.isMobile = Common.isMobile()
    },
    scrolled() {
      if (location.pathname == '/') {
        this.bgOpacity = window.scrollY / 480.0
      } else {
        this.bgOpacity = 1.0
      }
    },
    menuClicked() {
      this.$refs.bcHeaderMenu.showMenu()
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrolled)
    window.addEventListener('resize', this.resized)
    setTimeout(() => {
      this.resized()
      this.scrolled()
    }, 200)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resized)
    window.removeEventListener('scrolled', this.scrolled)
  }
}
</script>

<style scoped lang="scss">
@import "scoped.scss";

.bc-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;

  .bc-header-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $bc-color-bg-cream;
    opacity: 0;
  }

  .bc-header-content {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    max-width: 1328px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bc-logo {
      display: flex;
    }
    .bc-menu-button {
      width: 44px;
      height: 44px;
      img {
        max-height: 27px;
        object-fit: contain;
      }
    }
  }
}
</style>
