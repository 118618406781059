<template>
  <div class="page-home-business-m">

    <div class="bc-title-row" ref="bcTitleRow">
      <h1 :style="h1Style">HOW WE<br>EMPOWER</h1>
      <p>
        私たちは３つの事業で、<br>
        女性たちのライフスタイルをエンパワーします
      </p>
    </div>

    <div class="bc-business">

      <div class="bc-business-col first-col" ref="bcBusinessCol0">
        <div class="bc-image">
          <img src="./res/home_products.svg" />
        </div>
        <div class="pg-title-row">
          <h3 class="pg-number">01.</h3>
          <h3>products</h3>
        </div>
        <p>あたらしい選択肢を提案する商品開発</p>
      </div>

      <div class="bc-business-col" ref="bcBusinessCol1">
        <div class="bc-image">
          <img src="./res/home_media.svg" />
        </div>
        <div class="pg-title-row">
          <h3 class="pg-number">02.</h3>
          <h3>media</h3>
        </div>
        <p>あらたな気づきや知識を届ける情報発信</p>
      </div>

      <div class="bc-business-col" ref="bcBusinessCol2">
        <div class="bc-image">
          <img src="./res/home_community.svg" />
        </div>
        <div class="pg-title-row">
          <h3 class="pg-number">03.</h3>
          <h3>community</h3>
        </div>
        <p>
          女性同士が互いにエンパワーできる<br>
          コミュニティの提供
        </p>
      </div>

    </div>

  </div>
</template>

<script>
import Common from './common.js'

export default {
  data() {
    return {
      w: 0,
      show: false,
      showTitleRow: false,
      showBusinessCol0: false,
      showBusinessCol1: false,
      showBusinessCol2: false
    }
  },
  computed: {
    h1Style() {
      let fontSize = this.w * 0.1575
      return {
        fontSize: fontSize + 'px'
      }
    }
  },
  methods: {
    resized() {
      this.w = window.innerWidth
    },
    scrolled() {
      if (!this.show) {
        this.show = Common.showElement(this.$el, 0.1, 0)
      }
      if (!this.showTitleRow) {
        this.showTitleRow = Common.showElement(this.$refs.bcTitleRow, 0.5, 200)
      }
      if (!this.showBusinessCol0) {
        this.showBusinessCol0 = Common.showElement(this.$refs.bcBusinessCol0, 0.3, 0)
      }
      if (!this.showBusinessCol1) {
        this.showBusinessCol1 = Common.showElement(this.$refs.bcBusinessCol1, 0.3, 0)
      }
      if (!this.showBusinessCol2) {
        this.showBusinessCol2 = Common.showElement(this.$refs.bcBusinessCol2, 0.3, 0)
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.resized)
    setTimeout(() => {
      this.resized()
    }, 200)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resized)
  }
}
</script>

<style scoped lang="scss">
@import "scoped.scss";

.page-home-business-m {
  position: relative;
  width: 100%;
  padding: 48px 0;
  background-color: $bc-color-green;
  overflow: hidden;

  opacity: 0.0;
  transition: opacity 1.2s;
  &.show {
    opacity: 1.0;
  }

  .bc-title-row {
    position: relative;

    transform: scale(1.1);
    opacity: 0.0;
    transition: opacity 1.2s, transform 1.2s;
    &.show {
      transform: scale(1.0);
      opacity: 1.0;
    }

    h1 {
      text-align: center;
      @include font-nimbus-sans-extd();
      color: $bc-color-light-green;
      opacity: 0.25;
      line-height: 1em;
    }
    p {
      position: absolute;
      bottom: -1.5em;
      width: 100%;
      margin: 0;
      padding: 0 16px;
      text-align: center;
      font-size: 16px;
      color: white;
    }
  }

  .bc-business {
    width: 100%;
    margin: 24px 0 0;

    .bc-business-col {
      width: 100%;
      padding: 32px 24px 0;
      &.first-col {
        padding: 60px 24px 0;
      }

      opacity: 0.0;
      transition: opacity 1.2s;
      &.show {
        opacity: 1.0;
      }
      .bc-image {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 27%;
          display: block;
        }
      }
      .pg-title-row {
        width: 100%;
        display: flex;
        justify-content: center;

        h3 {
          color: white;
          @include font-clearface();
          font-weight: bold;
          font-size: 32px;
          letter-spacing: -0.025em;
          &.pg-number {
            margin: 4px 8px 0 0;
            @include font-nimbus-sans-extd();
            font-size: 12px;
          }
        }
      }
      p {
        text-align: center;
        color: #ffffff;
        line-height: 2em;
        font-size: 13px;
      }
    }
  }
}
</style>
